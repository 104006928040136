import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import querystring from 'query-string';
import { Flex, Text, Heading } from '@chakra-ui/core';
import { NavButton, Checkbox, TextInput } from '@zeta';
import { colors } from '@theme';
import Player from '@vimeo/player';
import { motion } from 'framer-motion';

import ZetaLogo from '@assets/ZetaLogo';
import RightArrow from '@assets/RightArrow';

import unsubscribe from '@lib/unsubscribeToList';

const Form = () => {
  const [updateMessage, setUpdateMessage] = useState(null);
  const { subscriber = {} } = useStoreState(state => state.emailSettings);
  const { getSubscriber, upsertSubscriber } = useStoreActions(
    actions => actions.emailSettings,
  );
  const { id, email, jcWtSub, jcUpSub, pfmSub, newsSub, rsrchSub } = subscriber;

  const [selected, setSelected] = useState('monthly');
  const [submitted, setSubmitted] = useState(false);
  const unsubAll = () => {
    let confirm = window.confirm(
      'This will unsubscribe you from all marketing emails from Zeta. Are you sure?',
    );

    if (!confirm) return false;
    upsertSubscriber({
      variables: {
        email,
        jcWtSub: false,
        jcUpSub: false,
        pfmSub: false,
        newsSub: false,
        rsrchSub: false,
      },
      callback: () => {
        setUpdateMessage(
          "Your preferences have been updated. We're sorry to see you go.",
        );
        setTimeout(() => {
          setUpdateMessage(null);
        }, 2500);
      },
    });
  };

  const handleSettingChange = ({ setting, value }) => {
    let toUpdate = {
      email: email,
    };
    toUpdate[setting] = value;
    upsertSubscriber({
      variables: toUpdate,
      callback: () => {
        setUpdateMessage('Your preferences have been updated.');
        setTimeout(() => {
          setUpdateMessage(null);
        }, 2500);
      },
    });
  };

  return submitted ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Text color="white">We've updated your preferences.</Text>
    </motion.div>
  ) : (
    <Flex direction="column">
      <Flex>
        <Checkbox
          color={colors.greenPea}
          value={pfmSub}
          borderColor={colors.greenPea}
          onChange={value => {
            handleSettingChange({ setting: 'pfmSub', value });
          }}
        >
          <Flex direction="column">
            <Text pb={0} fontWeight="semibold" color="white">
              Money Manager
            </Text>
            <Text color="white" fontWeight="light">
              Updates about Zeta's money manager for couples
            </Text>
          </Flex>
        </Checkbox>
      </Flex>
      <Flex mt={8}>
        <Checkbox
          color={colors.greenPea}
          value={rsrchSub}
          borderColor={colors.greenPea}
          onChange={value => {
            handleSettingChange({ setting: 'rsrchSub', value });
          }}
        >
          <Flex direction="column">
            <Text pb={0} fontWeight="semibold" color="white">
              Product Research
            </Text>
            <Text color="white" fontWeight="light">
              Zeta needs your help! Help us figure out what you want in our products
            </Text>
          </Flex>
        </Checkbox>
      </Flex>
      <Flex mt={8}>
        <Checkbox
          color={colors.greenPea}
          value={jcUpSub}
          borderColor={colors.greenPea}
          onChange={value => {
            handleSettingChange({ setting: 'jcUpSub', value });
          }}
        >
          <Flex direction="column">
            <Text pb={0} fontWeight="semibold" color="white">
              Joint Card Updates
            </Text>
            <Text color="white" fontWeight="light">
              Updates abut Zeta's Joint Card accounts
            </Text>
          </Flex>
        </Checkbox>
      </Flex>
      <Flex mt={8}>
        <Checkbox
          color={colors.greenPea}
          value={jcWtSub}
          borderColor={colors.greenPea}
          onChange={value => {
            handleSettingChange({ setting: 'jcWtSub', value });
          }}
        >
          <Flex direction="column">
            <Text pb={0} fontWeight="semibold" color="white">
              Joint Card Waitlist
            </Text>
            <Text color="white" fontWeight="light">
              Be on the waitlist for our upcoming Joint Card accounts for you and your
              partner
            </Text>
          </Flex>
        </Checkbox>
      </Flex>
      <Flex mt={8}>
        <Checkbox
          color={colors.greenPea}
          value={newsSub}
          borderColor={colors.greenPea}
          onChange={value => {
            handleSettingChange({ setting: 'newsSub', value });
          }}
        >
          <Flex direction="column">
            <Text pb={0} fontWeight="semibold" color="white">
              The Money Date
            </Text>
            <Text color="white" fontWeight="light">
              Our newsletter for couples about couples
            </Text>
          </Flex>
        </Checkbox>
      </Flex>
      <Flex mt={20}>
        <Text onClick={unsubAll} style={{ cursor: 'pointer' }} color={colors.iceberg}>
          Unsubscribe from all emails from Zeta
        </Text>
      </Flex>
      <Flex mt={8}>
        {updateMessage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Text color="white">{updateMessage}</Text>
          </motion.div>
        )}
      </Flex>
    </Flex>
  );
};

const EmailSettings = () => {
  const [searchMessage, setSearchMessage] = useState(null);
  const [emailSearch, setEmailSearch] = useState('');
  const { subscriber } = useStoreState(state => state.emailSettings);
  const { getSubscriber } = useStoreActions(actions => actions.emailSettings);

  const onEmailSearch = e => {
    setEmailSearch(e.target.value);
  };

  const handleSearch = () => {
    setSearchMessage(null);
    getSubscriber({
      variables: { email: emailSearch },
      callback: s => {
        if (!s) {
          setSearchMessage('Sorry, please try again.');
        }
      },
    });
  };

  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 1000,
  );

  let search = '';
  if (typeof window !== 'undefined') {
    search = window.location.search;
  }

  const { q } = querystring.parse(search);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowWidth);
      updateWindowWidth();
    }
    document.body.style = 'background-color: #0a3030;';

    if (q && Object.keys(subscriber).length === 0) {
      getSubscriber({ variables: { id: q } });
    }
  }, []);

  useEffect(() => {
    let player = null;
    if (windowWidth > 640)
      player = new Player('player', {
        id: 426010339,
        background: true,
        controls: false,
        responsive: 1,
        loop: true,
      });
    return () => player && player.destroy();
  }, []);

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  const goBack = e => {
    e.preventDefault();
    window.history.back();
  };

  const RenderPlayer = () => {
    if (windowWidth > 640) {
      return (
        <div style={styles(windowWidth).player}>
          <div id="player" style={{ margin: '0px auto' }} />
        </div>
      );
    } else {
      return <div />;
    }
  };

  const { email } = subscriber;

  return (
    <Flex backgroundColor={colors.primary}>
      <RenderPlayer />
      <div style={styles(windowWidth).content}>
        <Flex pt={[12, 4]}>
          <ZetaLogo />
        </Flex>
        <Flex mt={[12, 24]}>
          {!q && Object.keys(subscriber).length === 0 ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Flex direction="column" justify="center" minHeight="100%" flexGrow="1">
                <Heading as="h1" color={colors.shared} fontSize="4xl">
                  Let's see more of each other
                </Heading>
                <Text mt={4} fontWeight="semibold" fontSize="2xl" color={colors.white}>
                  Please enter your email to check your preferences.
                </Text>
                <Flex my={8}>
                  <Flex width="18rem">
                    <TextInput
                      placeholder="Your email"
                      onChange={onEmailSearch}
                      onKeyPress={handleSearch}
                    />
                  </Flex>
                  <Flex
                    ml={4}
                    width="2.5rem"
                    height="2.5rem"
                    minWidth="2.5rem"
                    maxHeight="2.5rem"
                    backgroundColor={colors.greenPea}
                    borderRadius="50%"
                    justify="center"
                    align="center"
                    cursor="pointer"
                    onClick={handleSearch}
                  >
                    <RightArrow fill={colors.white} height={16} />
                  </Flex>
                </Flex>
                <Flex>
                  {searchMessage && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Text color="white">{searchMessage}</Text>
                    </motion.div>
                  )}
                </Flex>
              </Flex>
            </motion.div>
          ) : (
            Object.keys(subscriber).length > 0 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Flex direction="column" justify="center" minHeight="100%" flexGrow="1">
                  <Heading as="h1" color={colors.shared} fontSize="4xl">
                    Let's see more of each other
                  </Heading>
                  <Text mt={4} fontWeight="semibold" fontSize="2xl" color={colors.white}>
                    Preferences for:{' '}
                    <Text as="span" color={colors.shared}>
                      {email}
                    </Text>
                  </Text>
                  <Text color={colors.iceberg} mt={6}>
                    Let us know how often you want to receive The Money Date. We'll update
                    <br />
                    your email preferences immediately.
                  </Text>
                  <Flex mt={12}>
                    <Form email={email} />
                  </Flex>
                </Flex>
              </motion.div>
            )
          )}
        </Flex>
      </div>
    </Flex>
  );
};

const styles = windowWidth => ({
  content: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    paddingLeft: windowWidth < 640 ? '2.5rem' : '4rem',
    paddingRight: windowWidth < 640 ? '2.5rem' : '0',
    paddingBottom: '2rem',
    height: '100%',
  },
  player: {
    backgroundColor: '#0a3030',
    width: '100%',
    marginTop: '-2rem',
    minWidth: '87rem',
  },
});

export default EmailSettings;
